<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogElement"
        persistent
        max-width="1400px"
        :retain-focus="false"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_element === 'add'"
                >Thêm Element</span
              >
              <span class="headline" v-else>Sửa Element</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogElement = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-switch
                    dense
                    v-model="is_pinned"
                    :label="`Pin ${is_pinned.toString()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <ElementChoose></ElementChoose>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogElement = false"
              >Đóng</v-btn
            >
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              @click="btnAddElement"
              outlined
              >Lưu</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters} from "vuex";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
import fileSupport from "./Support/fileSupport";
import elementSupport from "./Support/Element/elementSupport";
export default {
  name: "ElementModal",
  components: {
    ElementChoose: () => import("./ElementChoose"),
  },
  mixins: [
    constantTestBuilder,
    fileSupport,
    elementSupport,
  ],
  data() {
    return {
      is_pinned: true,
    };
  },
  computed: {
    ...mapState({
      type_form_element: (state) =>
        state.elementStore.type_form_element,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    dialogElement: {
      get() {
        return this.$store.state.elementStore.dialogElement;
      },
      set(value) {
        this.$store.commit("elementStore/setDialogElement", value);
      },
    },
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.elementStore.element_post_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.elementStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementAudioData",
          value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.elementStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementImageData",
          value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.elementStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementAudioFile",
          value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.elementStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementImageFile",
          value
        );
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementFormDisplay",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.elementStore.video_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.elementStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setVideoElementData",
          value
        );
      },
    },
    album_element_id: {
      get() {
        return this.$store.state.elementStore.album_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setAlbumElementId", value);
      },
    },
    album_element_data: {
      get() {
        return this.$store.state.elementStore.album_element_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setAlbumElementData",
          value
        );
      },
    },
    element_pdf_file: {
      get() {
        return this.$store.state.elementStore.element_pdf_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfFile", value);
      },
    },
    element_pdf_data: {
      get() {
        return this.$store.state.elementStore.element_pdf_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfData", value);
      },
    },
    element_youtube_data: {
      get() {
        return this.$store.state.elementStore.element_youtube_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementYoutube", value);
      },
    },
    element_flashcards: {
      get() {
        return this.$store.state.elementStore.element_flashcards;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFlashcards", value);
      },
    },
    element_gif_file: {
      get() {
        return this.$store.state.elementStore.element_gif_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementGifFile", value);
      },
    },
    element_gif_data: {
      get() {
        return this.$store.state.elementStore.element_gif_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementGifData", value);
      },
    },
  },
  watch: {
    dialogElement: function (val) {
      if (val) {
        this.setDataInput();
        if (!this.canOpenDialogElement()) {
          this.$nextTick(() => {
            this.dialogElement = false;
          })
        }
      } else {
        this.element_youtube_data = null;
      }
    },
    type_element(val, oldVal) {
      if (val) {
        if (!this.canChangeTypeElement(val)) {
          this.$nextTick(() => {
            this.type_element = oldVal;
          })
        }
      }
    }
  },
  created() {},
  methods: {
    setDataInput() {
      if (this.type_form_element === "add") {
        this.is_pinned = true;
        this.type_element = null;
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.element_id = null;
        this.album_element_id = null;
        this.album_element_data = [];
        this.element_pdf_file = null;
        this.element_pdf_data = null;
        this.element_youtube_data = null;
        this.element_flashcards = this.getDataDefaultElementFlashcard();
        this.element_gif_data = null;
        this.element_gif_file = null;
      } else {
        let section = this.skills[this.indexSkill].sections[this.indexSection];
        section = JSON.parse(JSON.stringify(section));
        this.type_element = section.element_type;
        this.element_post_data = section.element_type === "Post" ? section.element_data : null;
        this.element_image_data = section.element_type === "Image" ? section.element_data : null;
        this.element_audio_data = section.element_type === "Audio" ? section.element_data : null;
        this.element_pdf_data = section.element_type === "Pdf" ? section.element_data : null;
        this.video_element_data = section.element_type === "Video" ? section.element_data : null;
        this.element_youtube_data = section.element_type === "Embed_yt" ? section.element_data : null;
        this.element_gif_data = section.element_type === "Gif" ? section.element_data : null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_pdf_file = null;
        this.element_gif_file = null;
        this.is_pinned = section.is_element_pinned == 1 ? true : false;
        this.element_id = this.checkElementId(this.type_element, section.element_id);
        this.video_element_id = this.type_element === "Video" ? section.element_id : null;
        this.album_element_id = section.element_type === "Album" ? section.element_id : null;
        this.album_element_data = section.element_type === "Album" ? section.element_data : [];
        if (section.element_type === "Flashcard") {
          this.element_flashcards = section.element_data.map((item) => {
            item.example = item.example ? item.example : '';
            item.audio_data = item.audio_path;
            item.audio_file = null;
            item.image_data = item.image_path;
            item.image_file = null;
            return item;
          })
        } else {
          this.element_flashcards = this.getDataDefaultElementFlashcard();
        }
      }
    },
    checkElementId(type, element_id) {
      let check_element_id = element_id;
      if (type == "Video" || type == "Album") {
        check_element_id = null;
      }
      return check_element_id;
    },
    async btnAddElement() {
      let validate = this.validateFormElement();
      if (!validate) {
        return;
      }
      this.is_call_api = true;
      let self = this;
      let valueElement = this.getValueElement(this.type_element);
      if (this.type_element === "Video") {
        await this.uploadElement(self.type_element, valueElement, self.video_element_id, self.video_element_data);
      } else if (this.type_element === "Album") {
        await this.uploadElement(self.type_element, valueElement, self.album_element_id, self.album_element_data);
      } else if (this.type_element === "Flashcard") {
        await this.uploadElement(self.type_element, valueElement, self.element_id, self.element_flashcards);
      }
      else {
        let formData = new FormData();
        formData.append("type", valueElement);
        formData.append("section_id", this.section_id);
        formData.append("element_id", this.element_id);
        formData.append("element_form_display", this.element_form_display);
        if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
          formData.append("editor", this.checkElementData(this.type_element));
        }
        formData.append("is_element_pinned", self.is_pinned === true ? 1 : 0);
        if (this.checkElementFile(this.type_element) != null) {
          formData.append("file", this.checkElementFile(this.type_element));
        }
        if (this.type_element === "Audio") {
          let duration = null;
          if (!this.$utils.isValueInvalid(this.element_audio_file)) {
            duration = await this.getAudioDurationFromFile(this.element_audio_file);
          } else {
            duration = await this.getAudioDurationFromBase64OrUrl(this.element_audio_data);
          }
          let playtime_string = this.$options.filters.convertSecondToMS(duration);
          formData.append("duration", duration);
          formData.append("playtime_string", playtime_string);
        }

        this.is_call_api = true;
        await ApiService.post(
          "prep-app/test/draft/section/upload-element",
          formData
        )
          .then(function (response) {
            console.log(response)
            if (response) {
              self.skills[self.indexSkill].sections[self.indexSection].element_type = self.type_element;
              self.skills[self.indexSkill].sections[self.indexSection].element_data = self.checkElementData(self.type_element);
              self.skills[self.indexSkill].sections[self.indexSection].is_element_pinned = self.is_pinned;
              self.skills[self.indexSkill].sections[self.indexSection].element_id = response.data.element_code;
              self.$set(self.skills);
              self.type_element = null;
              self.element_data = null;
              self.dialogElement = false;
              self.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              self.is_call_api = false;
              self.messError("Upload dữ liệu Element bị lỗi. Liên hệ bộ phận tech để được hỗ trợ !!");
              console.log(error);
            }
          });
      }
    },
    async uploadElement(type, valueElement, elementId, elementData) {
      let vm = this;
      let formData = new FormData();
      formData.append("type", valueElement);
      formData.append("section_id", this.section_id);
      formData.append("element_form_display", this.element_form_display);
      formData.append("element_id", elementId);
      formData.append("is_element_pinned", this.is_pinned === true ? 1 : 0);
      if (type === "Flashcard") {
        elementData = elementData.map((item) => {
          item.element_id = elementId;
          item.example = !vm.$utils.isValueInvalid(item.example) ? item.example : null;
          return item;
        });
        formData.append("data_flashcard", JSON.stringify(elementData));
      }
      await ApiService.post("prep-app/test/draft/section/upload-element", formData)
          .then((response) => {
            console.log(response)
            if (response.status === 200 ) {
              vm.skills[vm.indexSkill].sections[vm.indexSection].element_type = vm.type_element;
              if (vm.type_element === "Flashcard") {
                elementData = response.data.flashcards.map((item) => {
                  item.example = item.example ? item.example : '';
                  return item;
                });
              }
              vm.skills[vm.indexSkill].sections[vm.indexSection].element_data = elementData;
              vm.skills[vm.indexSkill].sections[vm.indexSection].is_element_pinned = vm.is_pinned;
              vm.skills[vm.indexSkill].sections[vm.indexSection].element_id = elementId;
              vm.$set(vm.skills);
              vm.type_element = null;
              vm.element_data = null;
              vm.dialogElement = false;
              vm.is_call_api = false;
            }
          })
          .catch((error) => {
            if (error) {
              vm.is_call_api = false;
              vm.messError('Upload dữ liệu Element bị lỗi. Liên hệ bộ phận tech để được hỗ trợ !!')
            }
          });
    },
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    canChangeTypeElement(newType) {
      let flat = true;
      let section = this.skills[this.indexSkill].sections[this.indexSection];

      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          if (section.partConfig) {
            let partConfig = section.partConfig;
            if (
                partConfig.module === this.section_config_module_constant.PREP_TALK_MODULE &&
                partConfig.product_category_id === this.product_category_constant.PREPTALK_ENGLISH &&
                partConfig.offset === this.section_offset_constant.OFFSET_1
            ) {
              if (!(newType === "Video")) {
                this.messError("Chỉ được chọn Video cho section này");
                flat = false;
              }
            }
          }
        }

      }
      return flat;
    },
    canOpenDialogElement() {
      let flat = true;
      let section = this.skills[this.indexSkill].sections[this.indexSection];

      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          if (section.partConfig) {
            let partConfig = section.partConfig;
            if (
                partConfig.module === this.section_config_module_constant.PREP_TALK_MODULE &&
                partConfig.product_category_id === this.product_category_constant.PREPTALK_ENGLISH
            ) {
              if (
                  [
                      this.section_offset_constant.OFFSET_2,
                      this.section_offset_constant.OFFSET_3,
                  ].includes(partConfig.offset))
              {
                this.messError("Không thể thêm element cho section này");
                flat = false;
              }
            }
          }
        }

      }
      return flat;
    },
  },
};
</script>

<style></style>
